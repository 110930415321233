<template>
	<div>
	<div style="height: 650px;display: flex;">
		
		<div style="width: 50%;height: 100%; position: relative;">
			<div style="margin-left: 30px;font-size: 20px; width:200px;height: 50px;line-height: 50px; ">海报二维码:</div>
			<div style=" margin-left: 30px; width: 400px;height: 600px;background-size:cover" id="posterHtml" :style="{backgroundImage: 'url('+posterHtmlBg+')'}" v-show="true">
			
			<div class="qrcode" style="position: absolute;top:490px; margin-top:10px;margin-left: 110px;width: 100px;height: 70px;"><div style="width: 70px;height: 70px;" id="qrcodeImg"></div></div>
			</div>
			<!-- <div style="width: 50px;height:50px ;" @click="handleDownloadQrIMg()">保存</div> -->
			<el-button style="position: absolute;top:580px; left: 450px;" type="primary"  @click="handleDownloadQrIMg()">下载海报</el-button>
		</div>
	
  <!-- 海报html元素 -->
  
 <!-- <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    width="50%"
    :show-close="false"
  >
    <span>这是一段信息</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
  
  <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button> -->
  <div style="width: 50%;height: 100%;">
  <div class="handle-box" style="display: flex;margin-top: 50px;">
		<div style="line-height: 40px;width: 100px;">邀请码:</div>
      <el-input  v-model="code" placeholder="手机号" class="handle-input mr10"></el-input>
      <!-- <el-button type="primary" style="margin-left: 20px;">复制邀请码</el-button> -->
  	<!-- <el-button type="primary"  @click="handleSearch">导出EXCLE</el-button> -->
  </div>
  
  <div style="width: 100%;height: 150px; display: flex;margin-top: 20px; margin-bottom: 20px;">
  		<div style="line-height: 200px;width: 100px;">文案链接:</div>
      <el-input type="textarea" v-model="content" :rows="6" placeholder="文案" style="width: 400px;height: 150px;"></el-input>
      <!-- <el-button type="primary" style="margin-left: 20px;">复制邀请码</el-button> -->
  	<!-- <el-button type="primary"  @click="handleSearch">导出EXCLE</el-button> -->
  </div>
  <div class="text_2">二维码介绍：</div>
  <div class="text_1">海报二维码，新用户通过扫码后，跳往注册页，注册后形成绑定关系（邀请码自动填写）</div>
 <div class="text_2">邀请码绑定介绍：</div>
 <div class="text_1">（1）新用户通过你的广告知道你的邀请码后，在注册时填写邀请码，注册后形成绑定关系（APP、H5注册时都会有邀请码填写区域）</div>
 <div class="text_1">（2）未绑定的老用户知道你的邀请码后，在我的页，自动填写你的邀请码形成绑定关系</div>
 <div class="text_2">邀请链接绑定介绍：</div>
 <div class="text_1">新用户点击链接后，跳往注册页，注册后形成绑定关系（邀请码自动填写）</div>
 
 <!-- 
  <div style="width: 50px;height:50px ;" @click="createQrcode()">生成二维码</div>
  <div style="width: 50px;height:50px ;" @click="createPoster()">生成海报</div>
  <div style="width: 50px;height:50px ;" @click="handleDownloadQrIMg()">保存</div> -->
  
<!--  <div  style="width: 400px;height: 900px;" id="posterHtml" :style="{backgroundImage: 'url('+posterHtmlBg+')'}" v-show="true">
 -->  <!-- <div>{{posterContent}}</div> -->
  <!-- 二维码 -->
  <!-- </div> -->
  </div>
  </div>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  import html2canvas from 'html2canvas'
  import {GetConfig} from "../api/index";
  
  export default {
	name: "distribution",
    data() {
      return {
      posterHtmlBg: "", // 背景图
      posterImg: '', // 最终生成的海报图片
	  qr_content:"",
	  code:"",
	  content:"",
	  dialogVisible:true
    }
},
	created() {
		this.get_data()
	},
  methods:{
			// onCopy(){
		 //        this.$message({
		 //            message:'复制成功！',
		 //            type:'success'
		 //        })
		 //    },
		 //    // 复制失败
		 //    onError(){
		 //       this.$message({
		 //            message:'复制失败！',
		 //            type:'error'
		 //        })
		 //    },
	
			get_data(){
				GetConfig().then(res => {
					this.posterHtmlBg = res.data.poster
					this.qr_content = res.data.tg_url
					this.code =res.data.invitation_code
					this.content = res.data.tg_content
					this.createQrcode()
					// this.createPoster()
				});
			},
	  
		  createQrcode(){
		    const qrcodeImgEl = document.getElementById('qrcodeImg')
		    qrcodeImgEl.innerHTML = ''
		    let qrcode = new QRCode(qrcodeImgEl, {
		      width: 100,
		      height: 100,
		      colorDark: '#000000',
		      colorLight: '#ffffff',
		     correctLevel: QRCode.CorrectLevel.H
		    })
		    qrcode.makeCode(this.qr_content)
		  },
		 createPoster() {
		        const vm = this
		        const domObj = document.getElementById('posterHtml')
		        html2canvas(domObj, {
				  // dpi: window.devicePixelRatio * 4,
				  scale:6, 
		          useCORS: true,
		          allowTaint: false,
		          logging: false,
		          letterRendering: true,
		          onclone(doc) {
		            let e = doc.querySelector('#posterHtml')
		            e.style.display = 'block'
		          }
		        }).then(function(canvas) {
		          // 在微信里,可长按保存或转发
		          vm.posterImg = canvas.toDataURL('image/png')
				  
		        })
		      }, 
			  
			  
			  handleDownloadQrIMg() {
				  
				  
				  const vm = this
				  const domObj = document.getElementById('posterHtml')
				  html2canvas(domObj, {
				    // dpi: window.devicePixelRatio * 4,
				    scale:2, 
				    useCORS: true,
				    allowTaint: false,
				    logging: false,
				    letterRendering: true,
					width:400,
					height:600,
				    onclone(doc) {
				      let e = doc.querySelector('#posterHtml')
				      e.style.display = 'block'
				    }
				  }).then(function(canvas) {
				    // 在微信里,可长按保存或转发
				    const imgUrl  = canvas.toDataURL('image/png')
					// this.createPoster()
					  // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
					  if (window.navigator.msSaveOrOpenBlob) {
					    const bstr = atob(imgUrl.split(',')[1])
					    let n = bstr.length
					    const u8arr = new Uint8Array(n)
					    while (n--) {
					      u8arr[n] = bstr.charCodeAt(n)
					    }
					    const blob = new Blob([u8arr])
					    window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
					  } else {
					    // 这里就按照chrome等新版浏览器来处理
					    const a = document.createElement('a')
					    a.href = imgUrl
					    a.setAttribute('download', 'chart-download')
					    a.click()
					  }
				    
				  })
				 
			  }
  },
 }
</script>
<style>
	
	.handle-input {
	    width: 100px;
	    display: inline-block;
	}
	.text_1{
		margin-left: 20px; margin-bottom: 20px; color: #555555;height: 18px;line-height: 18px; font-size: 14px;display: flex; 
	}
	.text_2{
		width: 100%;
		color: #222222;
		font-size: 16px;
		/* width: 200px; */
		/* float: right; */
	}
</style>
